import React, {useState} from 'react';

import Modal from "./modal";
import Description from './description';
import TextInput from './text-input';
import TextArea from './text-area';
import DropDown from './drop-down';
import Button from './button';
import Loading from './loading';
import { verifyEmail } from '../utils/validation';

const TeamModal = ({ open, onClose }) => {
    const [email, setEmail] = useState('');
    const [experience, setExperience] = useState('');
    const [about, setAbout] = useState('');
    const [position, setPosition] = useState('');
    const [emailErr, setEmailErr] = useState();
    const [experienceErr, setExperienceErr] = useState();
    const [aboutErr, setAboutErr] = useState();
    const [positionErr, setPositionErr] = useState();
    const [loading, setLoading] = useState(false);

    const submitForm = () => {
        setLoading(true);
        const formData = new FormData();
        formData.append('email', email);
        formData.append('experience', experience);
        formData.append('message', about);
        formData.append('position', position);
        fetch("https://getform.io/f/1a18f0c3-b3e4-4087-8e0b-0d9c1f26065b", {
            method: "POST",
            body: formData,
        }).then((response) => {
            setLoading(false);
            window.location.href = response.url;
        })
        .catch((error) => {
            setLoading(false);
            // console.log(error);
        });
    }

    const validateForm = () => {
        if (!verifyEmail(email)) {
            setEmailErr("Please enter valid email");
        } else if (position?.length === 0) {
            setPositionErr("Please select the position");
        } else if (experience?.length === 0) {
            setExperienceErr("Please enter your years of experience");
        } else if (about?.length === 0) {
            setAboutErr("Please enter about you");
        } else {
            setEmailErr("");
            setPositionErr("");
            setExperienceErr("");
            setAboutErr("");
            submitForm();
        }
    }

    if (!open) return null;
    return (
        <Modal>
            <>
                <img
                    src={"/images/close-icon.svg"}
                    alt="arrow"
                    className="ml-4 group-hover:invisible transition-all duration-700 w-6 h-6 self-end mr-5 mt-7 cursor-pointer"
                    onClick={onClose}
                />
                <div className="flex flex-col md:flex-row md:px-[64px] md:pb-[112px] md:w-[997px] md:pt-8 px-[32px] pb-[56px]">
                    <div className="flex-[2_2_0%]">
                        <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-primary-text text-left font-normal mb-3">
                            Do you want to <span className="text-primary">work</span> with us?
                        </h1>
                        <Description text={"Please fill the form and we will get back to you"} />
                    </div>
                    <div className="flex-1">
                        <TextInput placeholder="Email" type="email" value={email} onChange={setEmail} error={emailErr}/>
                        <DropDown onSelectedPosition={setPosition} error={positionErr}/>
                        <TextInput placeholder="Years of experience" type="text" value={experience} onChange={setExperience} error={experienceErr}/>
                        <TextArea placeholder="About you" value={about} onChange={setAbout} error={aboutErr}/>
                        {loading ? <Loading /> : <Button title={"Submit"} className="py-3" onClick={validateForm} />}
                    </div>
                </div>
            </>
        </Modal>
    )
}

export default TeamModal;