import React, { memo, useEffect, useState } from "react";

const Hamburger = ({ className, onchange }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    onchange(isOpen);
  }, [isOpen]);

  return (
    <div
      className={[
        "w-14 h-10 my-12 transition-all duration-700 rotate-0 cursor-pointer group",
        className,
      ].join(" ")}
      onClick={() => setIsOpen(!isOpen)}
    >
      {Array.from({ length: 3 }).map((_, index) => {
        return (
          <span
            className={[
              "block absolute h-1 w-1/2 bg-primary rounded-lg left-0 transition-all first-of-type:top-0 top-2 last-of-type:top-4",
              isOpen
                ? "first-of-type:top-4 first-of-type:rotate-45 [&:nth-child(2)]:-left-16 [&:nth-child(2)]:opacity-0 last-of-type:-rotate-45 last-of-type:top-4"
                : "",
            ].join(" ")}
            key={index.toString()}
          />
        );
      })}
    </div>
  );
};

export default memo(Hamburger);
