import React from "react";

const Button = ({ onClick, title, className, isDisable }) => {
  return (
    <button
      className={[
        `px-11 py-4 ${isDisable ? 'text-primary-text border-primary-border' : 'text-primary border-primary hover:bg-primary hover:text-white'} font-medium border-2 flex items-center justify-between uppercase text-sm sm:text-lg rounded-full group transition-all duration-700 bg-white`,
        className,
      ].join(" ")}
      onClick={onClick}
      disabled={isDisable}
    >
      {title}
      {!isDisable && (
        <img
        src={"/images/button-link.svg"}
        alt="arrow"
        className="ml-4 group-hover:invisible transition-all duration-700"
      />
      )}
    </button>
  );
};

export default Button;
