import React from 'react';

const TextInput = ({placeholder, type, onChange, value, error}) => {
    return (
        <div className="mb-4">
            <input type={type} id="email" className="bg-white border border-input-border text-primary-text text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-2.5" placeholder={placeholder} required 
            value={value} onChange={(e) => onChange(e.target.value)} />
            {error?.length > 0 && <span className="text-xs text-primary">{error}</span>}
        </div>
    )
}

export default TextInput;
