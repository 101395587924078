import React, { memo } from "react";

const Description = ({ text, className, size }) => {
  const textSize = () => {
    switch (size) {
      case "medium":
        return "text-lg";
      case "large":
        return "text-xl";
      default:
        return "text-xl";
    }
  };

  return (
    <p
      className={["text-description font-normal", textSize(), className].join(
        " "
      )}
    >
      {text}
    </p>
  );
};

export default memo(Description);
