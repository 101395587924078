import React from "react";

const Shade = ({ className }) => {
  return (
    <div
      className={[
        "w-40 h-40 bg-primary blur-3xl absolute",
        className,
      ].join(" ")}
    />
  );
};

export default Shade;
