import React, { useState, useEffect } from "react";
import Container from "./container";
import Hamburger from "./hamburger";

import routes from "/static/constants/routes";

const Header = () => {
  const [activeNav, setActiveNav] = useState("home");
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const sections = document.querySelectorAll(".section");
    const onScroll = (event) => {
      if (event.target.documentElement.scrollTop > 60) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        if (event.target.documentElement.scrollTop >= sectionTop - 60) {
          setActiveNav(section.getAttribute("id"));
        }
      });
    };

    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <header>
      <div
        className={[
          "fixed w-full z-50 lgxl:absolute transition-all lgxl:shadow-none",
          isScrolled ? "bg-primary-background shadow-xl" : "bg-transparent",
        ].join(" ")}
      >
        <Container
          className={"flex lgxl:mt-7 justify-between w-full items-center"}
        >
          <img
            src={"/images/logo.svg"}
            alt="Cliqueraft"
            width={88.33}
            height={81}
            loading={"lazy"}
          />
          <Hamburger className={"lgxl:hidden"} onchange={setIsOpen} />
        </Container>
      </div>
      <nav
        className={[
          `fixed transition-all duration-100 w-40 bg-black text-primary-background  z-50 h-screen left-0 flex justify-center shadow-lg lgxl:w-auto lgxl:ml-8 lgxl:mt-10 lgxl:bg-transparent lgxl:shadow-none ${
            activeNav === "contact-us" ? "" : "lgxl:text-primary-text"
          } lgxl:translate-x-0`,
          isOpen ? "translate-x-0" : "-translate-x-full",
        ].join(" ")}
      >
        <ul className="inline-block my-auto text-center before:content-none first-of-type:before:h-28 before:w-0 before:border-r before:border-primary-border before:absolute before:border-dashed before:top-10 before:left-2/4 lgxl:before:content-['']">
          {routes.map(({ name, path }, index) => (
            <li
              key={index.toString()}
              className={
                "flex items-center mb-11 after:h-11 last-of-type:after:h-28 after:content-none after:w-0 after:border-r after:border-primary-border after:absolute after:border-dashed after:top-10 after:left-2/4 relative text-base font-medium lgxl:block lgxl:after:content-['']"
              }
            >
              <span
                className={`${
                  activeNav === path.replace("#", "") ? "text-primary" : ""
                } lgxl:hidden`}
              >
                ---
              </span>
              <a
                className={`p-2 block transition-all duration-700 
                ${activeNav === path.replace("#", "") ? "text-primary" : ""}`}
                href={path}
              >
                {name}
              </a>
            </li>
          ))}
        </ul>
      </nav>
    </header>
  );
};

export default Header;
