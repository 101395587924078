import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React, { useRef, useState, useEffect, memo } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";

import Button from "../components/button";
import Container from "../components/container";
import Description from "../components/description";
import H3 from "../components/h3";
import Header from "../components/header";
import Shade from "../components/shade";
import ClientModal from "../components/client-modal";
import TeamModal from "../components/team-modal";

const howWeDoIt = [
  {
    description:
      "We go through a Knowledge phase where we understand the problem and the challenges",
    image: "/images/Frame.svg",
  },
  {
    description:
      "We design a solution draft and let you review it to optimise for running cost and implementation time",
    image: "/images/draft-icon.svg",
  },
  {
    description: "We start implementation and keep you in the loop",
    image: "/images/laptop-icon.svg",
  },
  {
    description:
      "We hand-over the work along with knowledge transfer to help keep you going",
    image: "/images/check-icon.svg",
  },
];

const services = [
  {
    title: "Edge Computing",
    description:
      "Leverage our domain expertise in building ML models on resource constrained hardware",
  },
  {
    title: "Computer Vision",
    description:
      "Build smart solutions by leveraging computer vision to ssolve problems",
  },
  {
    title: "Frontend Development",
    description:
      "Being a frontend web development we have experience in building complex, innovative, responsive, and user-centric solutions. Our interfaces are designed with close attention to detail and look great on both desktop screens and mobile devices",
  },
  {
    title: "Backend Development",
    description:
      "Building extensible on-premise and cloud-based back-end solutions for mobile, web, desktop, and IoT systems that scale together with your growing business needs",
  },
  {
    title: "Mobile Development",
    description:
      "With an integrated approach to Mobile App Development, we aim at creating mobile apps that are intuitive, user-friendly, secure, and high-performing",
  },
  {
    title: "UI/UX Design",
    description:
      "Simple-to-use and user-friendly design is the key to any digital product. When a mobile or web UI is easy to understand, product adoption is much smoother for new users",
  },
];

const HomePage = ({ data }) => {
  const sliderRef = useRef(null);
  const contactUsRef = useRef(null);

  const [isClientModalOpen, setClientModalOpen] = useState(false);
  const [isTeamModalOpen, setTeamModalOpen] = useState(false);


  return (
    <>
      <Header />
      <main className="bg-primary-background">
        <section
          id="home"
          className="pt-52 section relative overflow-hidden md:pt-60 h-screen bg-[url('https://storage.googleapis.com/cliqueraft-website-assets/main-wave.gif')] bg-no-repeat bg-center bg-contain"
        >
          <Shade className="absolute -top-32 -left-32" />
          <Container>
            <header className="max-w-4xl mx-auto mb-12">
              <h1 className="text-2xl sm:text-4xl md:text-5xl lg:text-6xl text-primary-text text-center font-normal">
                We at <span className="text-primary">Cliqueraft</span> build
                products using Artificial intelligence at edge. We love creating
                new experiences for our clients.
              </h1>
            </header>
            <Button title={"Contact Us"} className="mx-auto" onClick={() => contactUsRef.current.scrollIntoView()} />
          </Container>
          <Shade className="absolute -right-20 -bottom-20" />
        </section>
        <section
          id="about-us"
          className="section py-20 md:py-32 relative overflow-hidden"
        >
          <Container>
            <article className="relative">
              <img
                src="/images/Frame.svg"
                alt="About Us"
                width={61}
                height={61}
                loading="lazy"
                className="absolute right-0 -top-16 md:-top-12"
              />
              <h2 className="text-3xl md:text-7xl font-normal text-primary-text mb-12 md:mb-24">
                A little bit <span className="text-primary">about us</span>
              </h2>
              <section className="lg:flex lg:gap-x-32 lg:justify-between">
                <article className="mb-6 lg:mb-0 lg:basis-1/2">
                  <H3 heading={"What do we do?"} />
                  <Description
                    text={`Cliqueraft is a SaaS company building microSaaS products to address
                    wide customer bases while focusing on delivering value to it\u0027s
                    users. We have a collective experience of 10+ years of building
                    products in Productivity, Finance, Game development and Security.`}
                  />
                </article>
                <article className="lg:basis-1/2">
                  <H3 heading={"How we do it?"} />
                  {howWeDoIt.map(({ description, image }, index) => (
                    <figure
                      key={index.toString()}
                      className="flex mb-7 items-start"
                    >
                      <img src={image} alt="" className="mr-6" loading="lazy" />
                      <figcaption>
                        <Description text={description} />
                      </figcaption>
                    </figure>
                  ))}
                </article>
              </section>
            </article>
          </Container>
          <Shade className={"-left-20 -bottom-20"} />
        </section>
        <section
          id="our-services"
          className="section py:20 md:py-32 relative overflow-hidden"
        >
          <Shade className={"-top-20 -left-20"} />
          <Container>
            <article className="flex flex-wrap lg:flex-nowrap gap-x-56">
              <h2 className="text-3xl md:text-7xl font-normal text-primary-text mb-6 md:mb-24 md:w-min inline-block lg:basis-1/3 lg:text-right lg:rotate-180 lg:writing-vertical-rl">
                Our <span className="text-primary"><br />Services</span>
              </h2>
              <ul className="lg:basis-8/12">
                {services.map(({ description, title }, index) => (
                  <li
                    key={index.toString()}
                    className="mb-7 flex gap-x-6 group"
                  >
                    <span className="text-primary text-4xl font-medium">
                      0{index + 1}
                    </span>
                    <article className="border-b border-b-primary-border group-last:border-none pb-7">
                      <H3 heading={title} className="mb-3" />
                      <Description text={description} />
                    </article>
                  </li>
                ))}
              </ul>
            </article>
          </Container>
          <Shade className={"-bottom-20 -right-20"} />
        </section>
        <section
          id="our-products"
          className="section py-20 md:py-32 relative overflow-hidden"
        >
          <Shade className={"-top-20 -right-20"} />
          <Container>
            <section className="relative">
              <h2 className="text-3xl md:text-7xl font-normal text-primary-text mb-12 md:mb-20">
                Our <span className="text-primary">Products</span>
              </h2>
              <Swiper
                modules={[Navigation]}
                ref={sliderRef}
                spaceBetween={50}
                slidesPerView={1}
                breakpoints={{
                  580: {
                    slidesPerView: 2,
                  },
                  900: {
                    slidesPerView: 3,
                  },
                }}
              >
                {data?.allContentfulPortfolio?.nodes?.map(
                  ({ id, title, description, image, projectLink }, index) => {
                    const _image = getImage(image);
                    return (
                      <SwiperSlide key={id}>
                        <a href={projectLink} target="_blank">
                          <article className="border-t border-t-slate-600 pt-5">
                            <div className="flex flex-row items-center">
                              <H3 heading={title} />
                              <a href={projectLink} target="_blank" className="mb-7">
                                <img src="/images/link-icon.svg" alt="link-icon" className="ml-2" />
                              </a>
                            </div>
                            <Description
                              text={description}
                              size="medium"
                              className={"mb-5"}
                            />
                            <GatsbyImage image={_image} alt={title} />
                          </article>
                        </a>
                      </SwiperSlide>
                    );
                  }
                )}
              </Swiper>
              {data?.allContentfulProducts?.nodes ? (
                <div className="flex items-center gap-8 absolute right-4 -bottom-8">
                  <button
                    onClick={() => {
                      if (!sliderRef.current) return;
                      sliderRef.current.swiper.slidePrev();
                    }}
                    className={"text-red-700 text-xl"}
                  >
                    &larr;
                  </button>
                  <button
                    onClick={() => {
                      if (!sliderRef.current) return;
                      sliderRef.current.swiper.slideNext();
                    }}
                    className={"text-red-700 text-xl"}
                  >
                    &rarr;
                  </button>
                </div>
              ) : null}
            </section>
          </Container>
        </section>
      </main>
      <footer
        id="contact-us"
        className="section bg-black pt-40 pb-7 relative overflow-hidden"
        ref={contactUsRef}
      >
        <Shade className={"-bottom-32 left-1/3"} />
        <Container>
          <div className="sm:flex gap-10 md:gap-52 pb-14 md:pb-60 border-b border-b-slate-400">
            <div className="mb-10 sm:mb-0">
              <h6 className="mb-4 text-white text-4xl">
                Want to discuss a <span className="text-red-600">project?</span>
              </h6>
              <p className="mb-14 text-white">
                Want to get started with your project and you need our help? Feel free to contact us.
              </p>
              <Button title={"Let's Talk"} onClick={() => setClientModalOpen(true)} />
            </div>
            <div>
              <h6 className="mb-4 text-white text-4xl">
                Want to become a part of <span className="text-red-600">Cliqueraft?</span>
              </h6>
              <p className="mb-14 text-white">
                We are hiring for our tech team. Contact us to know more
              </p>
              <Button title={"Join the team"} onClick={() => setTeamModalOpen(true)} />
            </div>
          </div>
          <div className="mt-11 sm:flex sm:justify-between">
            <div className="mb-5 sm:mb-0">
              <a className="block text-lg text-white mb-4 md:mb-7">
                hello@cliqueraft.com
              </a>
              <div className="flex gap-5 items-center">
                <a className="cursor-pointer" target="_blank" href="https://discord.gg/wxAWGfEn"><img src="/images/discord-white.svg" alt="discord-icon" /></a>
                <a className="text-white cursor-pointer" target="_blank" href='https://www.linkedin.com/company/cliqueraft/'>in</a>
              </div>
            </div>
            <div className="">
              <div className="flex gap-3 justify-between sm:justify-end mb-4 md:mb-7">
                <Link to={'/privacy-policy'} className="text-lg text-white cursor-pointer">Privacy policy</Link>
                <Link to={'/terms-and-condition'} className="text-lg text-white cursor-pointer">Terms</Link>
              </div>
              <p className="text-lg text-gray-500">
                (c) 2024 all rights reserved to cliqueraft
              </p>
            </div>
          </div>
        </Container>
      </footer>
      <ClientModal open={isClientModalOpen} onClose={() => setClientModalOpen(false)} />
      <TeamModal open={isTeamModalOpen} onClose={() => setTeamModalOpen(false)} />
    </>
  );
};

export const query = graphql`
  query {
    allContentfulPortfolio {
      nodes {
        id: contentful_id
        description
        title
        projectLink
        image {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
        }
      }
    }
  }
`;

export default memo(HomePage);

export const Head = () => <title>Cliqueraft</title>;
