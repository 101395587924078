const routes = [
    {
        name: 'Home',
        path: '#home'
    },
    {
        name: 'About Us',
        path: '#about-us'
    },
    {
        name: 'Services',
        path: '#our-services'
    },
    {
        name: 'Products',
        path: '#our-products'
    },
    {
        name: 'Contact Us',
        path: '#contact-us'
    },
];

export default routes;
