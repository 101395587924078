import React from 'react';

const TextArea = ({placeholder, value, onChange, error}) => {
    return (
        <div className="mb-6">
            <textarea id="message" rows="4" className="block p-2.5 w-full text-sm bg-white border border-input-border text-primary-text rounded-lg focus:ring-primary focus:border-primary " placeholder={placeholder} value={value} onChange={(e) => onChange(e.target.value)}/>
            {error?.length > 0 && <span className="text-xs text-primary">{error}</span>}
        </div>
    )
}

export default TextArea;
