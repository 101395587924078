import React, { useState } from 'react';

const positions = ['Marketing', 'Mobile App Development'];

const DropDown = ({onSelectedPosition, error}) => {
    const [toggleDropDown, setToggleDropDown] = useState(false);
    const [selectedPosition, setSelectedPosition] = useState('Select Position');

    const onSelectPosition = (position) => {
        setToggleDropDown(false);
        setSelectedPosition(position);
        onSelectedPosition(position);
    }

    return (
        <div className="flex justify-center mb-3">
            <div className="w-full">
                <div className="dropdown relative">
                    <button className="w-full bg-white border border-input-border text-primary-text text-sm rounded-lg focus:ring-primary focus:border-primary flex p-2.5 flex-row justify-between"
                        aria-expanded="false"
                        onClick={() => setToggleDropDown(true)}>
                        {selectedPosition}
                        <svg class="ml-2 w-4 h-4" aria-hidden="true" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
                        </svg>
                    </button>
                    <ul
                        className={`dropdown-menu min-w-max absolute bg-white text-base z-50 float-left py-2 list-none text-left rounded-lg shadow-lg mt-1 m-0 bg-clip-padding border-none ${toggleDropDown ? 'visible' : 'hidden'}`}
                        aria-labelledby="dropdownMenuButton1">
                        {positions.map((position, index) => (
                            <li key={index} onClick={() => onSelectPosition(position)}>
                                <a className="dropdown-item text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100 cursor-pointer">{position}</a>
                            </li>
                        ))}
                    </ul>
                    {error?.length > 0 && <span className="text-xs text-primary">{error}</span>}
                </div>
            </div>
        </div>
    )
}

export default DropDown;
