import React, { memo } from 'react';

const Modal = ({ children }) => {
    return (
        <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-modal-empty-background">
            <div className="w-full py-6 px-5 max-w-5xl">
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-modal-background outline-none focus:outline-none">
                    {children}
                </div>
            </div>
        </div>
    )
};

export default memo(Modal);